export class Operation {
    constructor(options) {
        this.operator = options.operator;
        this.label = options.label;
        this.arguments = options.arguments;
        this.validator = options.validator;

        this.applyFunction = options.applyFunction;
    }


}

export class Argument {
    constructor(options) {
        this.name = options.name;
        this.col = options.col;
        this.type = options.type;
        this.value = [];

        this.render = options.render ?? true;
    }

    static input(options) {
        return new InputArgument(options);
    }

    static select(options) {
        return new SelectArgument(options);
    }

    static currentField() {
        return new FieldArgument({
            name: 'CurrentField',
            render: false,
            current: true
        })
    }
}

export class InputArgument extends Argument {
    constructor(options) {
        super(options);

        this.inputType = options.inputType;
    }
}

export class SelectArgument extends Argument {
    constructor(options) {
        super(options);

        this.selectOptions = options.selectOptions;
    }
}

export class FieldArgument extends Argument {
    constructor(options) {
        super(options);

        this.current = options.current;
    }
}

export function getDefaultOperations(type) {
    switch (type) {
        case 'string':
            return [];
        case 'number':
            return [];
        case 'date':
        case 'datetime':
            return [
                new Operation({
                    operator: 'Add',
                    label: 'Add',
                    validator: (values) => {
                        if (values.length !== 3) { return false; }
                        return true;
                    },
                    applyFunction: ([item, value, unit]) => {
                        value = parseInt(value);
                        const date = new Date(item.value.valueOf());
                        switch (unit) {
                            case 'days':
                                date.setDate(date.getDate() + value);
                                break;
                            case 'hours':
                                date.setTime(date.getTime() + value * 60 * 60 * 1000);
                                break;
                            case 'minutes':
                                date.setTime(date.getTime() + value * 60 * 1000);
                                break;
                            case 'months':
                                date.setMonth(date.getMonth() + value);
                                break;
                            case 'years':
                                date.setFullYear(date.getFullYear() + value);
                                break;
                        }
                        return date;
                    },
                    arguments: [
                        Argument.currentField(),
                        Argument.input({ name: 'value', inputType: 'number', col: 5 }),
                        Argument.select({
                            name: 'unit', col: 3,
                            selectOptions: [
                                { value: 'days', label: 'Days' },
                                { value: 'hours', label: 'Hours' },
                                { value: 'minutes', label: 'Minutes' },
                                { value: 'months', label: 'Months' },
                                { value: 'years', label: 'Years' }
                            ],
                        }),
                    ],
                }),
                new Operation({
                    operator: 'Subtract',
                    label: 'Subtract',
                    validator: (values) => {
                        if (values.length !== 3) { return false; }
                        return true;
                    },
                    applyFunction: ([item, value, unit]) => {
                        const date = new Date(item.value.valueOf());
                        switch (unit) {
                            case 'days':
                                date.setDate(date.getDate() - value);
                                break;
                            case 'hours':
                                date.setTime(date.getTime() - value * 60 * 60 * 1000);
                                break;
                            case 'minutes':
                                date.setTime(date.getTime() - value * 60 * 1000);
                                break;
                            case 'months':
                                date.setMonth(date.getMonth() - value);
                                break;
                            case 'years':
                                date.setFullYear(date.getFullYear() - value);
                                break;
                        }
                        return date;
                    },
                    arguments: [
                        Argument.currentField(),
                        Argument.input({ name: 'value', inputType: 'number', col: 5 }),
                        Argument.select({
                            name: 'unit', col: 3,
                            selectOptions: [
                                { value: 'days', label: 'Days' },
                                { value: 'hours', label: 'Hours' },
                                { value: 'minutes', label: 'Minutes' },
                                { value: 'months', label: 'Months' },
                                { value: 'years', label: 'Years' }
                            ],
                        }),
                    ],
                }),
            ];
    }
}

export class FormulaObject {
    constructor(options) {
        this.operation = options.operation;
        this.value = options.value;

        this.currentField = options.currentField;
    }

    fillInCurrentField() {
        this.operation.arguments.forEach((arg, index) => {
            if (arg instanceof FieldArgument && arg.current) {
                this.value[index] = {
                    _argumentDisplay: `[${this.currentField}]`,
                    _argumentValue: this.currentField
                };
            }
        });
    }

    replaceFieldsWithValues(item) {
        this.operation.arguments.forEach((arg, index) => {
            if (arg instanceof FieldArgument) {
                this.value[index].value = item[this.value[index]._argumentValue];
            }
        });
    }

    toString() {
        return `${this.operation.operator}(${this.value.map(argument => argument._argumentDisplay ?? argument).join(', ')})`;
    }

    isValid() {
        return this.operation.validator(this.value);
    }
}